import { useQuery } from '@apollo/client';
import { EnabledFeaturesQuery } from 'graphql/generated/types/graphqlTypes';
import { ENABLED_FEATURES } from 'graphql/queries/featureToggle';
import { FeatureToggleConstants } from '../../../generated/phpConstants';

// TODO: ATM-4593 replace with enum generated for GraphQL from PHP enum
type FeatureToggleIdentifier = (typeof FeatureToggleConstants)[keyof typeof FeatureToggleConstants];

interface Props {
  identifier: FeatureToggleIdentifier;
  additionalCondition?: boolean;
  inverted?: boolean;
  children: React.ReactNode;
}

const FeatureToggle = ({ children, identifier, additionalCondition, inverted = false }: Props) => {
  const { data } = useQuery<EnabledFeaturesQuery>(ENABLED_FEATURES);

  const isEnabled =
    data?.enabledFeatures.includes(identifier) && (additionalCondition === undefined || additionalCondition);

  if (isEnabled !== inverted) {
    return <>{children}</>;
  }

  return null;
};

export default FeatureToggle;
